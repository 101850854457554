<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-textarea
          v-model="clinicAddress"
          label="Address"
          aria-required="required"
          required
          :rules="requiredRules"
          ref="address"
          id="address"
        ></v-textarea>
      </v-col>
      <!--v-col cols="12" sm="12" md="6">


        <v-combobox
          v-model="postcode"
          :items="postcodeList"
          :loading="isLoading"
          item-text="postcode"
          item-value="postcode"
          label="Postcode"
          placeholder="Postcode"
          append-icon="mdi-database-search"
          @change="setProvince"
          required
          :rules="requiredRules"
          ref="postcode"
          id="postcode"
        ></v-combobox>
      </v-col>

      <v-col cols="12" sm="12" md="6">
 
        <v-combobox
          v-model="province"
          :items="provinceList"
          :loading="isLoading"
          item-text="provinceThai"
          item-value="provinceThai"
          label="Province"
          placeholder="Province"
          required
          :rules="requiredRules"
          ref="province"
          id="province"
        >
        </v-combobox>
      </v-col>

      <v-col cols="12" sm="12" md="6">
        <v-combobox
          v-model="district"
          :items="ampherList"
          :loading="isLoading"
          item-text="districtThaiShort"
          item-value="districtThaiShort"
          label="District"
          placeholder="District"
          @change="changeDistrict"
          required
          :rules="requiredRules"
          ref="district"
          id="district"
        >
        </v-combobox>
      </v-col>

      <v-col cols="12" sm="12" md="6">
        <v-combobox
          v-model="tambon"
          :items="tambonList"
          :loading="isLoading"
          item-text="tambonThaiShort"
          item-value="tambonThaiShort"
          label="Tambon"
          placeholder="Tambon"
          required
          :rules="requiredRules"
          id="tambon"
        >
        </v-combobox>
      </v-col-->
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
export default {
  name: "AddressForm",
  components: {},
  props: {
    chkValid: {
      type: Boolean,
    },
    editAddress: {
      type: Object,
    },
  },
  data: () => ({
    hasError: false,
    clinicAddress: "",
    province: "",
    postcode: "",
    district: "",
    tambon: "",
    address: {
      address: "",
      postcode: "",
      province: "",
      district: "",
      tambon: "",
      zipcode: "",
    },
    postcodeList: [],
    provinceList: [],
    ampherList: [],
    tambonList: [],
    isLoading: false,
    requiredRules: [(v) => !!v || "This field is required"],
  }),
  mounted() {
    //this.searchAddressDataAutoComplete();
    console.log("mounted");
    console.log(this.editAddress.clinicAddress);
    if (typeof this.editAddress.clinicAddress !== "undefined") {
      //
      //async () => {
      console.log("init");
      this.init();
      //};
    } else {
      //
      // this.clinicAddress = this.editAddress.clinicAddress;
      // console.log(this.clinicAddress);
    }
  },
  computed: {
    requiredFields() {
      //console.log("requiredFields");
      return {
        address: this.clinicAddress,
        postcode: this.postcode,
        province: this.province,
        district: this.district,
        tambon: this.tambon,
        //zipcode: this.clinic.postcode,
      };
    },
  },
  methods: {
    init() {
      console.log("init");
      console.log(this.editAddress);
      this.clinicAddress = this.editAddress.clinicAddress;
      this.postcode = this.editAddress.postcode;
      this.tambon = this.editAddress.tambon;
      this.district = this.editAddress.district;
      this.province = this.editAddress.province;
    },
    filterDistrict(zipcode) {
      console.log("filterDistrict");
      let dupDistrict = "";
      this.ampherList = [];
      this.tambonList = [];
      return this.postcodeList
        .filter((item) => {
          return item.postcode == zipcode;
        })
        .map((item) => {
          item.districtThaiShort = item.districtThaiShort || null;
          if (item.districtThaiShort !== dupDistrict) {
            this.ampherList.push({ districtThaiShort: item.districtThaiShort });
            dupDistrict = item.districtThaiShort;
          }
        });
    },

    filterTambon(zipcode, district) {
      //console.log(zipcode);
      //console.log(district);
      console.log("filterTambon");
      this.tambonList = [];
      this.tambon = "";
      return this.postcodeList
        .filter((item) => {
          return item.postcode == zipcode.postcode;
        })
        .map((item) => {
          if (item.districtThaiShort == district.districtThaiShort) {
            this.tambonList.push({ tambonThaiShort: item.tambonThaiShort });
          }
        });
    },

    changeDistrict() {
      console.log("changeDistrict");
      this.tambonList = [];
      this.filterTambon(this.postcode, this.district);
    },

    setProvince() {
      //console.log(this.postcode);
      console.log("setProvince");
      if (this.postcode !== "") {
        this.provinceList = [{ provinceThai: this.postcode.provinceThai }];
        this.filterDistrict(this.postcode.postcode);
        this.filterTambon(this.postcode.postcode, null);
        this.province = this.postcode.provinceThai;
        this.district = null;
        this.tambon = null;
      }
    },
    async searchAddressDataAutoComplete() {
      const url = process.env.VUE_APP_LOYALTY_API_URI;
      this.loading = true;
      try {
        this.payloadBody = {
          type: "postcode",
          filter: "",
        };
        axios
          .post(
            url + "/v1/zipcode/getZipcodeData",
            this.payloadBody, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            this.postcodeList = response.data;
          });
      } catch (err) {
        this.responseStatus = {
          status: "Error",
          code: 500,
          message: err,
        };
      }
      this.loading = false;
    },
    async chkValidAddress() {
      let breakForEachException = {};

      //console.log("chk valid");
      //console.log(this.address);
      try {
        await Object.keys(this.address).forEach((a) => {
          let element = document.getElementById(a);
          if (element && element.value == "") {
            this.hasError = true;
            document.getElementById(a).focus();
            throw breakForEachException;
          }
        });
        //this.$emit("updateAddress", this.address);
      } catch (e) {
        //console.log("throw error");
        if (e != breakForEachException) throw e;
      }

      this.$emit("updateValid", false);
    },
  },
  watch: {
    address: {
      handler() {
        console.log("watch address");
        this.$emit("updateAddress", this.requiredFields);
      },
    } /*
    province: {
      handler() {
        //console.log("watch province");
        this.$emit("updateAddress", this.requiredFields);
      },
    },
    postcode: {
      handler() {
        //console.log("watch postcode");
        this.$emit("updateAddress", this.requiredFields);
      },
    },
    district: {
      handler() {
        //console.log("watch district");
        this.$emit("updateAddress", this.requiredFields);
      },
    },
    tambon: {
      handler() {
        //console.log("watch tambon");
        this.$emit("updateAddress", this.requiredFields);
      },
    },*/,
    clinicAddress: {
      handler() {
        console.log(this.clinicAddress);
        console.log(this.requiredFields);
        //this.address.address = this.clinicAddress;
        this.$emit("updateAddress", this.requiredFields);
      },
    },
    chkValid: {
      handler() {
        //console.log(this.chkValid);

        //if (this.chkValid == true) {
        this.chkValidAddress();
        //deep: true;
        //} else {
        //console.log("chkValid = false");
        //}
      },
    },
    editAddress: {
      handler(val) {
        console.log(val);
        if (typeof val.clinicAddress !== "undefined") {
          console.log("setting");
          this.clinicAddress = val.clinicAddress;
          this.postcode = val.postcode;
          this.tambon = val.tambon;
          this.district = val.district;
          this.province = val.province;
          //this.district = val.district;
          //this.tambon = val.tambon;
        }
      },
    },
  },
};
</script>
